import React from 'react'
import Nav from './nav/nav'



export default function Home(){


	return(
					<body style = {{maxHeight : window.innerHeight, height : window.innerHeight, fontFamily : "monospace"  }}>
								<Nav/>

					</body>

		)


}